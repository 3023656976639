<template>
  <div>
    <new-sale-form v-if="salesType === 'new_sale'" :headers-tab="headersTab" :client-units="clientUnitsData" :client-id="clientId" :sales-type="salesType" />
    <cross-sell-form
      v-if="salesType === 'cross_sell'"
      :headers-tab="headersTab"
      :client-units="clientUnitsData"
      :client-id="clientId"
      :sales-type="salesType"
    />
    <upsell-form v-if="salesType === 'upsell'" :headers-tab="headersTab" :client-units="clientUnitsData" :client-id="clientId" :sales-type="salesType" />
    <downsell-form v-if="salesType === 'downsell'" :headers-tab="headersTab" :client-id="clientId" :sales-type="salesType" />
    <temporary-churn-form v-if="salesType === 'temporary_churn'" :headers-tab="headersTab" :client-id="clientId" :sales-type="salesType" />
    <discount-form v-if="salesType === 'discount'" :headers-tab="headersTab" :client-id="clientId" :sales-type="salesType" />
    <return-temporary-churn-form v-if="salesType === 'return_temporary_churn'" :headers-tab="headersTab" :client-id="clientId" :sales-type="salesType" />
  </div>
</template>

<script>
import { QUERY_GET_CLIENT_UNITS_BY_CLIENT } from '@/modules/retailers/graphql'
import { getSaleTypeByValue } from '../../../../../constants/salesTypes'
export default {
  components: {
    NewSaleForm: () => import('./NewSaleForm.vue'),
    CrossSellForm: () => import('./CrossSellForm.vue'),
    UpsellForm: () => import('./UpsellForm.vue'),
    DownsellForm: () => import('./DownsellForm.vue'),
    TemporaryChurnForm: () => import('./TemporaryChurnForm.vue'),
    DiscountForm: () => import('./DiscountForm.vue'),
    ReturnTemporaryChurnForm: () => import('./ReturnTemporaryChurnForm.vue')
  },
  props: {
    headersTab: {
      type: [String, Number]
    },
    retailer: {
      type: Object
    }
  },
  data: () => ({
    clientId: '',
    salesType: '',
    clientUnitsData: []
  }),
  computed: {
    saleTypeMapped() {
      return getSaleTypeByValue(this.salesType)?.label
    },
    accounts() {
      return this.retailer?.accounts ? this.retailer.accounts.join(',') : ''
    }
  },
  apollo: {
    clientUnits: {
      query: QUERY_GET_CLIENT_UNITS_BY_CLIENT,
      fetchPolicy: 'no-cache',
      variables() {
        return { client_units: { client_id: this.$route.params.id } }
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['crm']
        }
      },
      update({ clientUnits }) {
        this.clientUnitsData = clientUnits
      }
    }
  },
  mounted() {
    this.$emit('update:headersTab', 'acoes')
    this.salesType = this.$route.query['sales']
    this.clientId = this.$route.params.id
  }
}
</script>
