const SALES_TYPES = [
  {
    value: 'new_sale',
    label: 'Venda'
  },
  {
    value: 'temporary_churn',
    label: 'Abono Temporário'
  },
  {
    value: 'revert_temporary_churn',
    label: 'Retorno Abono Temporário'
  },
  {
    value: 'discount',
    label: 'Renegociação de MRR'
  },
  {
    value: 'cross_sell',
    label: 'Cross Sell'
  },
  {
    value: 'upsell',
    label: 'Upsell'
  },
  {
    value: 'downsell',
    label: 'Downsell'
  },
  {
    value: 'churn',
    label: 'Churn'
  }
]

const getSaleTypeByValue = value => SALES_TYPES.find(({ value: type }) => type === value)

module.exports = {
  SALES_TYPES,
  getSaleTypeByValue
}
